section[role='hero'] {
  position: relative;
  background: #000;
  height: 500px;

  .videoBox {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .heroInfo {
    padding: 1rem;
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    text-align: center;

    background: rgba(0, 0, 0, 0.445);
    box-shadow: 0 8px 12px 0 rgba(111, 160, 33, 0.103);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    img {
      max-height: 95px;
    }
    h1 {
      text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.589);
    }
    p {
      text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.692);
      color: white;
      font-size: 18px;
      font-weight: 300;
      width: 100%;
    }
    @media all and (max-width: 440px) {
      width: 300px;
      img {
        width: 100%;
      }
      .home_main_h1 {
        font-size: 25px !important;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

//layout

.layoutWrapper1 {
  margin: 12rem 0;
  padding: 3rem 0;
  @include responsive-for(xlg) {
    margin: 0;
  }
  @include responsive-for(sm) {
    padding: 1rem 0;
  }
  .whyImage1 {
    padding-top: 3rem;
    @include responsive-for(sm) {
      padding-top: 1rem;
    }
    img {
      width: 110%;
      @include responsive-for(lg) {
        width: 90%;
        object-fit: contain;
      }
    }
    @include responsive-for(lg) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .row {
    width: 100%;
    margin: auto;
  }
  .whyContentWrapper1 {
    height: 100%;
    position: relative;
    .whyContent1 {
      position: absolute;
      top: -6%;
      left: -10%;
      background: color(secondary, tint);
      padding: 2rem;
      border-radius: $radius;
      @include responsive-for(md) {
        position: static;
      }

      .preTitle {
        @include responsive-for(sm) {
          margin-top: 2rem;
          padding-top: 1rem;
        }
      }
      .whyLIst1 {
        padding: 0;

        &__box {
          align-items: baseline;
          justify-content: flex-start;

          p {
            margin-left: 1rem;
            color: color(text-light);
            width: 85%;
            margin-top: 1rem;
            letter-spacing: 0.5px;
            @include responsive-for(xlg) {
              margin-top: 0;
              margin-bottom: 0.5rem !important;
              width: 95%;
              line-height: 150%;
            }
          }
        }
      }
      @include responsive-for(xs) {
        padding: 0;
      }
    }
  }
}

//layout changed both required
.layoutWrapper {
  margin: 4rem 0;
  padding: 3rem 0;
  .whyImage {
    img {
      width: 100%;
      border-radius: $radius;
    }
    @include responsive-for(lg) {
      //display: none;
      width: 90%;
      object-fit: contain;
      margin: 10px auto;
    }
  }
  .whyContentWrapper {
    height: 100%;
    position: relative;
    width: 100%;
    .whyContent {
      // position: absolute;
      // top: -6%;
      // left: -10%;
      background: color(secondary, tint);
      padding: 2rem;
      border-radius: $radius;
      @include responsive-for(sm) {
        padding: 0.5rem;
      }
      .preTitle {
        @include responsive-for(sm) {
          margin-top: 2rem;
        }
      }
      .whyLIst {
        padding: 0;
        &__box {
          align-items: baseline;
          justify-content: flex-start;

          p {
            margin-left: 1rem;
            color: color(text-light);
            width: 85%;
            margin-top: 1rem;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

//category section

.categoriesWrapper {
  margin: 7rem 0;
  @include responsive-for(xlg) {
    margin: 1rem 0;
  }
  @include responsive-for(sm) {
    margin: 0;
  }
  .container {
    .row {
      .w-50 {
        @include responsive-for(md) {
          width: 100% !important;
        }
      }
    }
  }
}

.categoryCard {
  border: $border;
  border-radius: $radius;
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
  &__image {
    img {
      width: 100%;
      height: 18rem;
      object-fit: contain;
    }
  }
}

//layoutTwo

.layoutTwo {
  .layoutWrapper {
    @include responsive-for(xlg) {
      margin: 8rem 0 2rem 0;
      padding: 0;
    }
    @include responsive-for(md) {
      margin-top: 4rem;
    }
    @include responsive-for(sm) {
      margin-top: 2rem;
    }
    .row {
      width: 100%;
      margin-right: 0;
    }
    .whyContentWrapper {
      .whyContent {
        padding-left: 7rem;
        position: absolute;
        top: -6%;
        left: 0;
        right: -5rem;
        p {
          width: 95%;
          line-height: 200%;
          color: color(text-light);
          font-weight: 300;
          margin-top: 1rem;
          @include responsive-for(xlg) {
            width: 100%;
            line-height: 170%;
          }
        }
        @include responsive-for(xlg) {
          padding-left: 10%;
        }
        @include responsive-for(lg) {
          position: static;
          padding-left: 5%;
        }
      }
      .whyContentCopy {
        padding-left: 7rem;
        visibility: hidden;
        p {
          width: 95%;
          line-height: 200%;
          color: color(text-light);
          font-weight: 300;
          margin-top: 1rem;
          @include responsive-for(xlg) {
            width: 100%;
            line-height: 170%;
          }
        }
        @include responsive-for(lg) {
          display: none;
        }
      }
      @include responsive-for(xlg) {
        height: 100%;
      }
    }
  }
}

.howPaichoWrapper {
  .w-50 {
    @include responsive-for(lg) {
      width: 100% !important;
    }
  }
  .farmer {
    // border: $border;
    padding: 1rem;
    box-shadow: $shadow;
    @include responsive-for(xlg) {
      padding: 1rem 0;
    }
    .cse {
      @include responsive-for(sm) {
        margin-top: 1rem;
      }
    }
    p {
      margin-top: 1rem;
      @include responsive-for(xlg) {
        width: 100% !important;
      }
    }
    @include responsive-for(xs) {
      padding: 0.2rem;
    }
  }
  .farmerImage {
    height: 100%;
    margin-top: 1.2rem;
    img {
      height: 100%;
      @media all and (max-width: 1366px) {
        width: 100%;
        height: auto;
      }
    }
    @include responsive-for(md) {
      margin-bottom: 10px;
    }
    @include responsive-for(xlg) {
      margin-top: 3rem;
    }
    @include responsive-for(lg) {
      margin-top: 6rem;
    }
    @include responsive-for(md) {
      margin-top: 0;
    }
  }

  .farmerToMarket {
    margin-top: 5rem;
    margin-bottom: 7rem;
    padding: 2rem;
    img {
      width: 100%;
    }
    @include responsive-for(xs) {
      display: none;
    }
  }
}

//widget

.widget {
  background: color(secondary, base);
  padding: 1rem;
  text-align: center;
  border-radius: $radius;

  h3 {
    color: white;
    margin-top: 1.5rem;
  }
  @include responsive-for(xs) {
    margin-top: 1.5rem;
  }
}

.testimonials {
  margin-top: 5rem;
  background: #f3f7fb;
  padding: 1rem;

  .testiCard {
    min-height: 420px;
    padding: 4rem 11rem;
    box-shadow: $shadow;
    background: white;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      width: 100% !important;
    }
    &__icon {
      margin: 1rem 0;
    }
    &__text {
      font-style: italic;
      color: color(text-dark);
      width: 100%;
    }
    &__avatar {
      margin-top: 1rem;
      display: flex;
      img {
        margin-right: 1rem;
        height: 4rem;
        width: 4rem;
        border-radius: calc(4rem);
      }

      &--name {
        color: color(text-light);
        font-size: 22px;
      }
      &--post {
        color: color(text-light);
        font-size: 14px;
      }
    }
    @include responsive-for(md) {
      padding: 1.5rem;
    }
  }
  @include responsive-for(xs) {
    padding: 0.2rem;

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }
}

//iso certification
.isoWrapper {
  margin-bottom: 5rem;
  &-isoContent {
    margin-top: 2rem;
    @include responsive-for(sm) {
      width: 100%;
    }
  }

  &-image {
    img {
      width: 100%;
      height: 10rem;
      object-fit: contain;
    }
  }
}

//award

.awardWrapper {
  margin-bottom: 5rem;
  &-image {
    padding: 1rem;
    border: $border;
    img {
      width: 100%;
      height: 29rem;
      object-fit: cover;

      @include responsive-for(sm) {
        height: 16rem;
      }
    }
  }
}
//stats holder

.statsWrapper {
  margin-bottom: 5rem;
  background: color(secondary, base);

  .stats {
    padding: 2rem 0;
    text-align: center;
    &__number {
      font-size: 55px;
      font-weight: 500;
      color: white;
    }
    &__title {
      font-size: 22px;
      color: white;
    }
    @include responsive-for(sm) {
      padding: 1rem 0;
    }
  }
}

.scrolltop {
  background: color(primary, base);
  color: white;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  display: inline;
  position: fixed;
  border-radius: $border;
  bottom: 5rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
}

.notVisible {
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  display: none;
  transition: visibility 0.5s ease-in-out;
  z-index: 99;
}
