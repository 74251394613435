section[role='Footer'] {
  margin-top: 8rem;
  background: color(secondary, base);

  .foooterInfo {
    margin-top: 3rem;
    img {
      width: 12rem;
    }
    p {
      color: white;
      line-height: 180%;
      width: 100%;
      margin-top: 2rem;
      @include responsive-for(xlg) {
        line-height: 145%;
      }
    }
  }

  //links

  .Links {
    color: white;
    &-title {
      color: white;
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    &-box {
      margin-top: 2rem;
      padding: 0;
      &-item {
        margin-top: 1rem;

        &:hover {
          text-decoration: underline white solid;
        }
        a {
          color: #fff;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  //contact info

  .contact {
    color: white;
    &-title {
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    &-box {
      padding: 0;
      &-item {
        margin-top: 2rem;
        display: flex;

        @include responsive-for(sm) {
          margin-top: 1rem;
        }
        .iconHolder {
          margin-right: 1rem;
        }
        .addHolder {
          &-value {
            font-size: 14px;
            font-weight: 200;
            letter-spacing: 1px;
            color: white !important;
          }
        }
      }
    }
  }

  .socialMedia {
    color: white;
    &-title {
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    .socialWrapper {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;

      &-icons {
        cursor: pointer;
        margin-right: 1rem;
        border: 1px solid white;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: calc(2.5rem);
        @include flex-center();
        transition: background-color ease-in 0.2s;

        i {
          color: color(gray, four);
        }

        &:hover {
          background-color: white;
          i {
            color: color(secondary, base);
          }
        }
      }
    }
  }
  @include responsive-for(xlg) {
    margin-top: 5rem;
  }
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}
