//flexbetween

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// title-pretitle

.preTitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2rem;
  color: color(secondary, base);
}

.mainTitle {
  color: color(text-dark);
  font-weight: 600 !important;
  letter-spacing: -1px;
}
