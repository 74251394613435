.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
    background-size: 100px 100%;
    background-repeat: no-repeat;
    animation: shimmer 1s ease infinite;

    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton {
  &-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-header {
    width: 20rem;
  }
  &-content {
    width: 25rem;
  }
  @include responsive-for(sm) {
    &-header,
    &-content {
      width: 90%;
    }
  }

  &-award {
    height: 29rem;

    @include responsive-for(sm) {
      height: 16rem;
    }
  }

  &-card {
    border: $border;
    border-radius: $radius;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
  }

  &-para {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
