.contactWrapper {
  margin-top: 3rem;

  .office {
    border: $border;
    padding: 1rem;
    &__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 1rem;
      &--box {
        padding: 1rem;
        width: 49%;
        border: $border;
        .office_items_h3 {
          @media all and (min-width: 768px) and (max-width: 992px) {
            font-size: 20px !important;
          }
          @include responsive-for(xs) {
            font-size: 1.2rem !important;
          }
        }
        p {
          width: 100%;
          margin-top: 1rem;
          @media all and (min-width: 768px) and (max-width: 992px) {
            font-size: 14px !important;
            margin: 0.4rem;
          }
        }
        @media all and (min-width: 768px) and (max-width: 992px) {
          padding: 0.5rem;
        }
        @include responsive-for(xs) {
          padding: 0.5rem;
        }
        @media all and (max-width: 480px) {
          width: 100%;
          &:last-child {
            margin-top: 1rem;
          }
        }
      }
      @media all and (max-width: 360px) {
        flex-wrap: wrap;
      }
    }
  }

  .Mapsection {
    border: $border;
    margin-top: 1rem;

    iframe {
      width: 100%;
      height: 500px;

      @include responsive-for(sm) {
        height: 350px;
      }
    }
  }

  .formWrapper {
    border: $border;
    padding: 1rem;
    @include responsive-for(md) {
      margin-top: 1rem;
    }
  }
}

//news section

.newsWrapper {
  margin-top: 3rem;

  .newsBox {
    border: $border;
    padding: 1rem;
    margin-top: 1rem;

    h3 {
      color: color(secondary, base);
      margin-top: 1rem;
    }
  }
}
