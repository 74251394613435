section [role='outletsTab'] {
  margin-top: 3rem;

  .outletBox {
    &-image {
      margin-bottom: 2rem;
      height: 17rem;
      overflow: hidden;
      border-radius: $radius;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .nav-link {
    width: 100% !important;
    padding: 1rem;
    text-align: center;
    border-radius: $radius !important;
    border: none !important;
    background: rgba(243, 243, 243, 0.411);
  }
  .nav-pills .nav-link.active {
    color: #ffffff !important;
    background: #8dc73d !important;
    font-weight: 600;
    border-bottom: 2px solid;
    border-bottom-color: #8dc73d;
    width: 9rem;
    border-radius: 0;
    text-align: center;
  }
}

.outletCard {
  border: $border;
  border-radius: 6px;
  overflow: hidden;
  height: 100%;

  &-title {
    background: color(secondary, base);
    padding: 1rem;
    color: white;
  }
  &-info {
    padding: 1rem;
    color: color(text-dark);
    i {
      margin-right: 1rem;
    }
  }
}
