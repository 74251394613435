.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: $h1;
}
.h2 {
  font-size: $h2;
}
.h3 {
  font-size: $h3;
}

.color {
  &-primary {
    color: color(primary, base);
  }
  &-gray {
    &1 {
      color: color(gray, one);
    }
    &2 {
      color: color(gray, two);
    }
    &3 {
      color: color(gray, three);
    }
    &4 {
      color: color(gray, four);
    }
  }
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: color(primary, base) !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: color(primary, base) !important;
  border-color: color(primary, base) !important;
}

//modal body here

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: $text-xlg;
  &-title {
    text-align: center;
    margin-bottom: 1rem;
  }
}

//tabs navigation

.nav {
  justify-content: center;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: color(text-light);
  font-weight: 500 !important;
  background: color(gray, four) !important;
  text-align: center;
  border-radius: 10rem !important;
  margin: 1rem 0.5rem;
  padding: 1rem 2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: color(secondary, base) !important;
  background: color(secondary, tint) !important;

  font-weight: 600;

  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: color(text-light) !important;
  cursor: pointer;
}

// bootstrap customization tab buttons

.nav-pills .nav-link {
  font-weight: $font-bold;
  color: color(text-dark);
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: color(primary, base);
  border-radius: 3rem;
}
//cursor pointer

.cpntr {
  cursor: pointer;
}

a {
  color: color(secondary, base);
}
