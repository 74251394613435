/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 48px;
}

.h2 {
  font-size: 36px;
}

.h3 {
  font-size: 24px;
}

.color-primary {
  color: #8DC73D;
}
.color-gray1 {
  color: #B3B3B3;
}
.color-gray2 {
  color: #CCCCCC;
}
.color-gray3 {
  color: #E6E6E6;
}
.color-gray4 {
  color: #F4F4F4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #8DC73D !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #8DC73D !important;
  border-color: #8DC73D !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav {
  justify-content: center;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #4D4D4D;
  font-weight: 500 !important;
  background: #F4F4F4 !important;
  text-align: center;
  border-radius: 10rem !important;
  margin: 1rem 0.5rem;
  padding: 1rem 2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #005AAB !important;
  background: #F3F7FB !important;
  font-weight: 600;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #4D4D4D !important;
  cursor: pointer;
}

.nav-pills .nav-link {
  font-weight: 700;
  color: #333333;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #8DC73D;
  border-radius: 3rem;
}

.cpntr {
  cursor: pointer;
}

a {
  color: #005AAB;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #333333;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 48px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 36px !important;
  font-weight: 500 !important;
  color: #333333;
}
@media all and (max-width: 576px) {
  h2 {
    font-size: 1.7rem !important;
  }
}

h3 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

p {
  width: 80%;
  line-height: 200%;
  color: #4D4D4D;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #005AAB;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3CC13B;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #333333;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #005AAB;
}

.cpr {
  color: #8DC73D;
}

.cprt {
  color: #ecffd1;
}

.bpr {
  background: #8DC73D;
}

.bprt {
  background: #ecffd1;
}

.cse {
  color: #005AAB;
}

.cset {
  color: #F3F7FB;
}

.bse {
  background: #005AAB;
}

.bset {
  background: #F3F7FB;
}

.csu {
  color: #3CC13B;
}

.bsu {
  background: #3CC13B;
}

.csut {
  color: #D7F3D7;
}

.bsut {
  background-color: #D7F3D7;
}

.cwa {
  color: #F3BB1C;
}

.bwa {
  background: #F3BB1C;
}

.cwat {
  color: #FDF1D1;
}

.bwat {
  background: #FDF1D1;
}

.cda {
  color: #F03738;
}

.bda {
  background: #F03738;
}

.bdat {
  background: #FCD7D7;
}

.bcdat {
  background: #FCD7D7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preTitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.2rem;
  color: #005AAB;
}

.mainTitle {
  color: #333333;
  font-weight: 600 !important;
  letter-spacing: -1px;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  line-height: 48px;
  width: 95%;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary:not(:disabled) {
  border-color: #8DC73D;
  color: white;
  background-color: #8DC73D;
}
.bton--primary:not(:disabled):hover {
  background-color: #85e400;
  border-color: #85e400;
  color: white;
}
.bton--secondary {
  border-color: #005AAB;
  color: white;
  background-color: #005AAB;
}
.bton--secondary:hover {
  background-color: #0172d4;
  border-color: #0172d4;
  color: white;
}
.bton--secondary:active {
  border-color: #e78a34;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #8DC73D;
  color: #8DC73D;
  background-color: #FFFFFF;
}
.bton--ghost:hover {
  background-color: #85e400;
  border-color: #85e400;
  color: white;
}
.bton--ghost--danger {
  border-color: #F03738;
  background: #FCD7D7;
  color: #F03738;
}
.bton--ghost--danger:hover {
  background-color: #F03738;
  border-color: #F03738;
  color: white;
}
.bton--secondary {
  border-color: white;
  color: white;
  background-color: transparent;
}
.bton--secondary:hover {
  background: white;
  color: #005AAB;
}
.bton--danger {
  background: #F03738;
  color: white;
  border: #F03738;
}
.bton--nacked {
  border: none;
  color: rgb(20, 20, 185);
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 1rem;
  color: #4D4D4D;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 0.3rem 1rem;
  color: #4D4D4D;
  display: inline-block;
}

.navMain {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.15);
}

.navWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navWrapper__navBrand {
  margin-bottom: 0;
}
.navWrapper__navBrand img {
  width: 5rem;
}
@media all and (max-width: 768px) {
  .navWrapper__navBrand img {
    width: 4rem;
  }
}
.navWrapper__navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.navWrapper__navItems--items {
  padding: 1rem 1.5rem;
}
@media screen and (max-width: 1040px) {
  .navWrapper__navItems {
    display: none;
  }
}
.navWrapper__mdNav {
  display: none;
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav {
    display: block;
  }
  .navWrapper__mdNav .open_mdNav {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.8rem;
  }
  .navWrapper__mdNav .mdNav_list_container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.719);
    transition: all 300ms linear;
    display: flex;
    justify-content: flex-end;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    background-color: white;
    width: 25%;
    min-width: 25rem;
    height: 100%;
    padding: 1rem 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child {
    display: flex;
    justify-content: space-between;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container {
    padding-top: 0.8rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container .close_mdNav {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 1.3rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:not(:first-child) {
    margin-left: 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li .mdnavBrand img {
    width: 5rem;
    margin-left: 1.5rem;
  }
}
@media all and (max-width: 1040px) and (max-width: 576px) {
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    width: 100%;
    min-width: 100%;
  }
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav .showmdNav {
    right: 0;
  }
}

@media all and (max-width: 1040px) {
  #dropdown-basic {
    padding: 0 !important;
  }
  .dropdown-menu {
    margin-top: 1rem !important;
  }
}
.activeSidebar {
  color: #8DC73D;
}

.notActive {
  color: #4D4D4D;
}

section[role=navDrop] .btn-success {
  color: #4D4D4D;
  background-color: #ffffff;
  border: none;
}
section[role=navDrop] .dropdown-item.active,
section[role=navDrop] .dropdown-item:active {
  background-color: transparent;
}
section[role=navDrop] .btn-check:active + .btn-success,
section[role=navDrop] .btn-check:checked + .btn-success,
section[role=navDrop] .btn-success.active,
section[role=navDrop] .btn-success:active,
section[role=navDrop] .show > .btn-success.dropdown-toggle {
  color: #4D4D4D;
  background-color: #ffffff;
  border: none;
}

section[role=Footer] {
  margin-top: 8rem;
  background: #005AAB;
}
section[role=Footer] .foooterInfo {
  margin-top: 3rem;
}
section[role=Footer] .foooterInfo img {
  width: 12rem;
}
section[role=Footer] .foooterInfo p {
  color: white;
  line-height: 180%;
  width: 100%;
  margin-top: 2rem;
}
@media all and (max-width: 1200px) {
  section[role=Footer] .foooterInfo p {
    line-height: 145%;
  }
}
section[role=Footer] .Links {
  color: white;
}
section[role=Footer] .Links-title {
  color: white;
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .Links-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .Links-box {
  margin-top: 2rem;
  padding: 0;
}
section[role=Footer] .Links-box-item {
  margin-top: 1rem;
}
section[role=Footer] .Links-box-item:hover {
  -webkit-text-decoration: underline white solid;
          text-decoration: underline white solid;
}
section[role=Footer] .Links-box-item a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
section[role=Footer] .contact {
  color: white;
}
section[role=Footer] .contact-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .contact-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .contact-box {
  padding: 0;
}
section[role=Footer] .contact-box-item {
  margin-top: 2rem;
  display: flex;
}
@media all and (max-width: 576px) {
  section[role=Footer] .contact-box-item {
    margin-top: 1rem;
  }
}
section[role=Footer] .contact-box-item .iconHolder {
  margin-right: 1rem;
}
section[role=Footer] .contact-box-item .addHolder-value {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  color: white !important;
}
section[role=Footer] .socialMedia {
  color: white;
}
section[role=Footer] .socialMedia-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .socialMedia-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .socialMedia .socialWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
}
section[role=Footer] .socialMedia .socialWrapper-icons {
  cursor: pointer;
  margin-right: 1rem;
  border: 1px solid white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ease-in 0.2s;
}
section[role=Footer] .socialMedia .socialWrapper-icons i {
  color: #F4F4F4;
}
section[role=Footer] .socialMedia .socialWrapper-icons:hover {
  background-color: white;
}
section[role=Footer] .socialMedia .socialWrapper-icons:hover i {
  color: #005AAB;
}
@media all and (max-width: 1200px) {
  section[role=Footer] {
    margin-top: 5rem;
  }
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  animation: shimmer 1s ease infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skeleton-header {
  width: 20rem;
}
.skeleton-content {
  width: 25rem;
}
@media all and (max-width: 576px) {
  .skeleton-header, .skeleton-content {
    width: 90%;
  }
}
.skeleton-award {
  height: 29rem;
}
@media all and (max-width: 576px) {
  .skeleton-award {
    height: 16rem;
  }
}
.skeleton-card {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.skeleton-para {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

section[role=hero] {
  position: relative;
  background: #000;
  height: 500px;
}
section[role=hero] .videoBox {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section[role=hero] .heroInfo {
  padding: 1rem;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.445);
  box-shadow: 0 8px 12px 0 rgba(111, 160, 33, 0.103);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
section[role=hero] .heroInfo img {
  max-height: 95px;
}
section[role=hero] .heroInfo h1 {
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.589);
}
section[role=hero] .heroInfo p {
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.692);
  color: white;
  font-size: 18px;
  font-weight: 300;
  width: 100%;
}
@media all and (max-width: 440px) {
  section[role=hero] .heroInfo {
    width: 300px;
  }
  section[role=hero] .heroInfo img {
    width: 100%;
  }
  section[role=hero] .heroInfo .home_main_h1 {
    font-size: 25px !important;
  }
  section[role=hero] .heroInfo p {
    font-size: 14px;
  }
}

.layoutWrapper1 {
  margin: 12rem 0;
  padding: 3rem 0;
}
@media all and (max-width: 1200px) {
  .layoutWrapper1 {
    margin: 0;
  }
}
@media all and (max-width: 576px) {
  .layoutWrapper1 {
    padding: 1rem 0;
  }
}
.layoutWrapper1 .whyImage1 {
  padding-top: 3rem;
}
@media all and (max-width: 576px) {
  .layoutWrapper1 .whyImage1 {
    padding-top: 1rem;
  }
}
.layoutWrapper1 .whyImage1 img {
  width: 110%;
}
@media all and (max-width: 992px) {
  .layoutWrapper1 .whyImage1 img {
    width: 90%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media all and (max-width: 992px) {
  .layoutWrapper1 .whyImage1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
.layoutWrapper1 .row {
  width: 100%;
  margin: auto;
}
.layoutWrapper1 .whyContentWrapper1 {
  height: 100%;
  position: relative;
}
.layoutWrapper1 .whyContentWrapper1 .whyContent1 {
  position: absolute;
  top: -6%;
  left: -10%;
  background: #F3F7FB;
  padding: 2rem;
  border-radius: 6px;
}
@media all and (max-width: 768px) {
  .layoutWrapper1 .whyContentWrapper1 .whyContent1 {
    position: static;
  }
}
@media all and (max-width: 576px) {
  .layoutWrapper1 .whyContentWrapper1 .whyContent1 .preTitle {
    margin-top: 2rem;
    padding-top: 1rem;
  }
}
.layoutWrapper1 .whyContentWrapper1 .whyContent1 .whyLIst1 {
  padding: 0;
}
.layoutWrapper1 .whyContentWrapper1 .whyContent1 .whyLIst1__box {
  align-items: baseline;
  justify-content: flex-start;
}
.layoutWrapper1 .whyContentWrapper1 .whyContent1 .whyLIst1__box p {
  margin-left: 1rem;
  color: #4D4D4D;
  width: 85%;
  margin-top: 1rem;
  letter-spacing: 0.5px;
}
@media all and (max-width: 1200px) {
  .layoutWrapper1 .whyContentWrapper1 .whyContent1 .whyLIst1__box p {
    margin-top: 0;
    margin-bottom: 0.5rem !important;
    width: 95%;
    line-height: 150%;
  }
}
@media all and (max-width: 480px) {
  .layoutWrapper1 .whyContentWrapper1 .whyContent1 {
    padding: 0;
  }
}

.layoutWrapper {
  margin: 4rem 0;
  padding: 3rem 0;
}
.layoutWrapper .whyImage img {
  width: 100%;
  border-radius: 6px;
}
@media all and (max-width: 992px) {
  .layoutWrapper .whyImage {
    width: 90%;
    -o-object-fit: contain;
       object-fit: contain;
    margin: 10px auto;
  }
}
.layoutWrapper .whyContentWrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.layoutWrapper .whyContentWrapper .whyContent {
  background: #F3F7FB;
  padding: 2rem;
  border-radius: 6px;
}
@media all and (max-width: 576px) {
  .layoutWrapper .whyContentWrapper .whyContent {
    padding: 0.5rem;
  }
}
@media all and (max-width: 576px) {
  .layoutWrapper .whyContentWrapper .whyContent .preTitle {
    margin-top: 2rem;
  }
}
.layoutWrapper .whyContentWrapper .whyContent .whyLIst {
  padding: 0;
}
.layoutWrapper .whyContentWrapper .whyContent .whyLIst__box {
  align-items: baseline;
  justify-content: flex-start;
}
.layoutWrapper .whyContentWrapper .whyContent .whyLIst__box p {
  margin-left: 1rem;
  color: #4D4D4D;
  width: 85%;
  margin-top: 1rem;
  letter-spacing: 0.5px;
}

.categoriesWrapper {
  margin: 7rem 0;
}
@media all and (max-width: 1200px) {
  .categoriesWrapper {
    margin: 1rem 0;
  }
}
@media all and (max-width: 576px) {
  .categoriesWrapper {
    margin: 0;
  }
}
@media all and (max-width: 768px) {
  .categoriesWrapper .container .row .w-50 {
    width: 100% !important;
  }
}

.categoryCard {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}
.categoryCard__image img {
  width: 100%;
  height: 18rem;
  -o-object-fit: contain;
     object-fit: contain;
}

@media all and (max-width: 1200px) {
  .layoutTwo .layoutWrapper {
    margin: 8rem 0 2rem 0;
    padding: 0;
  }
}
@media all and (max-width: 768px) {
  .layoutTwo .layoutWrapper {
    margin-top: 4rem;
  }
}
@media all and (max-width: 576px) {
  .layoutTwo .layoutWrapper {
    margin-top: 2rem;
  }
}
.layoutTwo .layoutWrapper .row {
  width: 100%;
  margin-right: 0;
}
.layoutTwo .layoutWrapper .whyContentWrapper .whyContent {
  padding-left: 7rem;
  position: absolute;
  top: -6%;
  left: 0;
  right: -5rem;
}
.layoutTwo .layoutWrapper .whyContentWrapper .whyContent p {
  width: 95%;
  line-height: 200%;
  color: #4D4D4D;
  font-weight: 300;
  margin-top: 1rem;
}
@media all and (max-width: 1200px) {
  .layoutTwo .layoutWrapper .whyContentWrapper .whyContent p {
    width: 100%;
    line-height: 170%;
  }
}
@media all and (max-width: 1200px) {
  .layoutTwo .layoutWrapper .whyContentWrapper .whyContent {
    padding-left: 10%;
  }
}
@media all and (max-width: 992px) {
  .layoutTwo .layoutWrapper .whyContentWrapper .whyContent {
    position: static;
    padding-left: 5%;
  }
}
.layoutTwo .layoutWrapper .whyContentWrapper .whyContentCopy {
  padding-left: 7rem;
  visibility: hidden;
}
.layoutTwo .layoutWrapper .whyContentWrapper .whyContentCopy p {
  width: 95%;
  line-height: 200%;
  color: #4D4D4D;
  font-weight: 300;
  margin-top: 1rem;
}
@media all and (max-width: 1200px) {
  .layoutTwo .layoutWrapper .whyContentWrapper .whyContentCopy p {
    width: 100%;
    line-height: 170%;
  }
}
@media all and (max-width: 992px) {
  .layoutTwo .layoutWrapper .whyContentWrapper .whyContentCopy {
    display: none;
  }
}
@media all and (max-width: 1200px) {
  .layoutTwo .layoutWrapper .whyContentWrapper {
    height: 100%;
  }
}

@media all and (max-width: 992px) {
  .howPaichoWrapper .w-50 {
    width: 100% !important;
  }
}
.howPaichoWrapper .farmer {
  padding: 1rem;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
}
@media all and (max-width: 1200px) {
  .howPaichoWrapper .farmer {
    padding: 1rem 0;
  }
}
@media all and (max-width: 576px) {
  .howPaichoWrapper .farmer .cse {
    margin-top: 1rem;
  }
}
.howPaichoWrapper .farmer p {
  margin-top: 1rem;
}
@media all and (max-width: 1200px) {
  .howPaichoWrapper .farmer p {
    width: 100% !important;
  }
}
@media all and (max-width: 480px) {
  .howPaichoWrapper .farmer {
    padding: 0.2rem;
  }
}
.howPaichoWrapper .farmerImage {
  height: 100%;
  margin-top: 1.2rem;
}
.howPaichoWrapper .farmerImage img {
  height: 100%;
}
@media all and (max-width: 1366px) {
  .howPaichoWrapper .farmerImage img {
    width: 100%;
    height: auto;
  }
}
@media all and (max-width: 768px) {
  .howPaichoWrapper .farmerImage {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 1200px) {
  .howPaichoWrapper .farmerImage {
    margin-top: 3rem;
  }
}
@media all and (max-width: 992px) {
  .howPaichoWrapper .farmerImage {
    margin-top: 6rem;
  }
}
@media all and (max-width: 768px) {
  .howPaichoWrapper .farmerImage {
    margin-top: 0;
  }
}
.howPaichoWrapper .farmerToMarket {
  margin-top: 5rem;
  margin-bottom: 7rem;
  padding: 2rem;
}
.howPaichoWrapper .farmerToMarket img {
  width: 100%;
}
@media all and (max-width: 480px) {
  .howPaichoWrapper .farmerToMarket {
    display: none;
  }
}

.widget {
  background: #005AAB;
  padding: 1rem;
  text-align: center;
  border-radius: 6px;
}
.widget h3 {
  color: white;
  margin-top: 1.5rem;
}
@media all and (max-width: 480px) {
  .widget {
    margin-top: 1.5rem;
  }
}

.testimonials {
  margin-top: 5rem;
  background: #f3f7fb;
  padding: 1rem;
}
.testimonials .testiCard {
  min-height: 420px;
  padding: 4rem 11rem;
  box-shadow: 1px 1px 14px 6px rgba(189, 189, 189, 0.062745098);
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.testimonials .testiCard p {
  width: 100% !important;
}
.testimonials .testiCard__icon {
  margin: 1rem 0;
}
.testimonials .testiCard__text {
  font-style: italic;
  color: #333333;
  width: 100%;
}
.testimonials .testiCard__avatar {
  margin-top: 1rem;
  display: flex;
}
.testimonials .testiCard__avatar img {
  margin-right: 1rem;
  height: 4rem;
  width: 4rem;
  border-radius: 4rem;
}
.testimonials .testiCard__avatar--name {
  color: #4D4D4D;
  font-size: 22px;
}
.testimonials .testiCard__avatar--post {
  color: #4D4D4D;
  font-size: 14px;
}
@media all and (max-width: 768px) {
  .testimonials .testiCard {
    padding: 1.5rem;
  }
}
@media all and (max-width: 480px) {
  .testimonials {
    padding: 0.2rem;
  }
  .testimonials .carousel-control-prev,
  .testimonials .carousel-control-next {
    display: none;
  }
}

.isoWrapper {
  margin-bottom: 5rem;
}
.isoWrapper-isoContent {
  margin-top: 2rem;
}
@media all and (max-width: 576px) {
  .isoWrapper-isoContent {
    width: 100%;
  }
}
.isoWrapper-image img {
  width: 100%;
  height: 10rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.awardWrapper {
  margin-bottom: 5rem;
}
.awardWrapper-image {
  padding: 1rem;
  border: 1px solid rgb(230, 230, 230);
}
.awardWrapper-image img {
  width: 100%;
  height: 29rem;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 576px) {
  .awardWrapper-image img {
    height: 16rem;
  }
}

.statsWrapper {
  margin-bottom: 5rem;
  background: #005AAB;
}
.statsWrapper .stats {
  padding: 2rem 0;
  text-align: center;
}
.statsWrapper .stats__number {
  font-size: 55px;
  font-weight: 500;
  color: white;
}
.statsWrapper .stats__title {
  font-size: 22px;
  color: white;
}
@media all and (max-width: 576px) {
  .statsWrapper .stats {
    padding: 1rem 0;
  }
}

.scrolltop {
  background: #8DC73D;
  color: white;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  display: inline;
  position: fixed;
  border-radius: 1px solid rgb(230, 230, 230);
  bottom: 5rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
}

.notVisible {
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  display: none;
  transition: visibility 0.5s ease-in-out;
  z-index: 99;
}

section[role=heroComponent] {
  position: relative;
  background: #000;
  height: 400px;
}
@media all and (max-width: 576px) {
  section[role=heroComponent] {
    height: 450px;
  }
}
section[role=heroComponent] .imageBox {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section[role=heroComponent] .content {
  width: 45%;
  min-width: 300px;
  padding: 2rem;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 12px 0 rgba(111, 160, 33, 0.103);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
section[role=heroComponent] .content p {
  color: white;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
}
@media all and (max-width: 768px) {
  section[role=heroComponent] .content {
    padding: 2rem 1rem;
  }
  section[role=heroComponent] .content h1 {
    font-size: 30px !important;
  }
  section[role=heroComponent] .content p {
    font-size: 14px;
  }
}
.layoutWrapper2 {
  margin: 2rem 0;
  padding: 3rem 0;
}
@media all and (min-width: 768px) {
  .layoutWrapper2:nth-child(odd) .row {
    flex-direction: row-reverse;
  }
  .layoutWrapper2:nth-child(odd) .whyContent2 {
    padding-left: 7rem;
  }
}
.layoutWrapper2 .whyImage2 {
  padding-top: 3rem;
}
.layoutWrapper2 .whyImage2 img {
  width: 100%;
}
@media all and (max-width: 992px) {
  .layoutWrapper2 .whyImage2 img {
    width: 90%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media all and (max-width: 992px) {
  .layoutWrapper2 .whyImage2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
.layoutWrapper2 .row {
  width: 100%;
  margin: auto;
  align-items: center;
}
@media all and (min-width: 768px) {
  .layoutWrapper2 .row * {
    padding: 0;
  }
}
.layoutWrapper2 .whyContent2 {
  background: #F3F7FB;
  padding: 2rem;
  border-radius: 6px;
}
.layoutWrapper2 .whyContent2 ul {
  padding: 0;
  list-style-image: url("../assets/arrow.png");
  margin-left: 2rem;
}
.layoutWrapper2 .whyContent2 ul li {
  padding-left: 1rem;
  width: 95%;
  line-height: 200%;
  color: #4D4D4D;
  font-weight: 300;
  margin-top: 0.1rem;
}
@media all and (max-width: 1200px) {
  .layoutWrapper2 .whyContent2 p {
    width: 100%;
    line-height: 180%;
  }
}
@media all and (max-width: 480px) {
  .layoutWrapper2 .whyContent2 {
    padding: 1rem;
  }
}
@media all and (max-width: 768px) {
  .layoutWrapper2 {
    margin: 0 0 2rem 0;
    padding: 1rem 0;
  }
}

.chairmanWrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.chairmanWrapper p {
  width: 100%;
}
.chairmanWrapper-image {
  height: 22rem;
}
.chairmanWrapper-image img {
  height: 22rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px 4px 0 0;
}
@media all and (min-width: 992px) and (max-width: 1200px) {
  .chairmanWrapper-image img {
    height: 15rem;
    margin-top: 4rem;
  }
}
.chairmanWrapper-image figcaption {
  border-radius: 0 0 4px 4px;
  text-align: center;
  background: #005AAB;
  padding: 1rem;
  color: white;
}
@media all and (min-width: 480px) and (max-width: 992px) {
  .chairmanWrapper-image {
    width: 80%;
    margin: auto;
  }
}
@media all and (max-width: 992px) {
  .chairmanWrapper-content {
    margin-top: 4rem;
  }
}
@media all and (max-width: 1200px) {
  .chairmanWrapper-content p {
    line-height: 170%;
  }
}

.boardWrapper {
  padding: 3rem;
  margin-top: 2rem;
}
@media all and (min-width: 768px) {
  .boardWrapper:nth-child(even) .row {
    flex-direction: row-reverse;
  }
}
.boardWrapper-image {
  background: #F3F7FB;
  padding: 2rem;
  border-radius: 6px;
  height: 100%;
  margin: 0;
}
.boardWrapper-image img {
  height: 20rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
}
@media all and (min-width: 576px) and (max-width: 992px) {
  .boardWrapper-image img {
    height: 30rem;
  }
}
.boardWrapper-image-post {
  padding-top: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  color: #005AAB;
}
.boardWrapper-image-name {
  text-align: center;
  padding-top: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
}
.boardWrapper-content {
  height: 100%;
}
.boardWrapper-content p {
  width: 100%;
  padding: 1rem;
}
@media all and (max-width: 480px) {
  .boardWrapper {
    padding: 0;
  }
}

.bwhite {
  background-color: white !important;
}

.teamHolder {
  margin-top: 5rem;
}
.teamHolder .teambox {
  border: 1px solid rgb(230, 230, 230);
  margin-top: 2rem;
}
.teamHolder .teambox img {
  padding: 1rem;
  width: 100%;
  height: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.teamHolder .teambox figcaption {
  text-align: center;
  margin-bottom: 0.5rem;
}

section [role=outletsTab] {
  margin-top: 3rem;
}
section [role=outletsTab] .outletBox-image {
  margin-bottom: 2rem;
  height: 17rem;
  overflow: hidden;
  border-radius: 6px;
}
section [role=outletsTab] .outletBox-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section [role=outletsTab] .nav-link {
  width: 100% !important;
  padding: 1rem;
  text-align: center;
  border-radius: 6px !important;
  border: none !important;
  background: rgba(243, 243, 243, 0.411);
}
section [role=outletsTab] .nav-pills .nav-link.active {
  color: #ffffff !important;
  background: #8dc73d !important;
  font-weight: 600;
  border-bottom: 2px solid;
  border-bottom-color: #8dc73d;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.outletCard {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
}
.outletCard-title {
  background: #005AAB;
  padding: 1rem;
  color: white;
}
.outletCard-info {
  padding: 1rem;
  color: #333333;
}
.outletCard-info i {
  margin-right: 1rem;
}

.productsWrapper {
  margin-top: 3rem;
}
.productsWrapper p {
  width: 80%;
}
@media all and (max-width: 768px) {
  .productsWrapper p {
    width: 100%;
  }
}

.galleryWrapper {
  margin-top: 3rem;
}
.galleryWrapper .mediaWrapper {
  margin-top: 1rem;
}
.galleryWrapper .mediaWrapper iframe {
  height: 17rem;
  width: 100%;
}
.galleryWrapper .mediaWrapper img {
  height: 17rem;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background: rgba(236, 236, 236, 0.356);
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .factoryContainer {
    max-width: 90% !important;
  }
}

.factoryWrapper {
  margin-top: 4rem;
}
.factoryWrapper .factoryProducts {
  margin-top: 4rem;
}
.factoryWrapper .factoryProducts img {
  margin-top: 3rem;
  width: 100%;
  border-radius: 6px;
}
.factoryWrapper .processWrapper {
  margin-top: 4rem;
}
.factoryWrapper .processWrapper img {
  width: 100%;
}
@media all and (max-width: 992px) {
  .factoryWrapper .processWrapper img {
    margin-top: 4rem;
  }
}
@media all and (max-width: 992px) {
  .factoryWrapper .processWrapper .process_desc {
    width: 100%;
    line-height: 170% !important;
  }
}
.factoryWrapper .processWrapper .factDetails {
  margin-top: 5rem;
}
@media all and (min-width: 768px) {
  .factoryWrapper .processWrapper .factDetails:nth-child(even) .row {
    flex-direction: row-reverse;
  }
}
.factoryWrapper .processWrapper .factDetails-image img {
  width: 100%;
}
.factoryWrapper .processWrapper .factDetails-desc {
  color: #3CC13B;
}
.factoryWrapper .processWrapper .factDetails-desc ul {
  list-style-type: disc;
}
.factoryWrapper .processWrapper .factDetails-desc ul li {
  margin-top: 1rem;
}

.contactWrapper {
  margin-top: 3rem;
}
.contactWrapper .office {
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
}
.contactWrapper .office__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.contactWrapper .office__items--box {
  padding: 1rem;
  width: 49%;
  border: 1px solid rgb(230, 230, 230);
}
@media all and (min-width: 768px) and (max-width: 992px) {
  .contactWrapper .office__items--box .office_items_h3 {
    font-size: 20px !important;
  }
}
@media all and (max-width: 480px) {
  .contactWrapper .office__items--box .office_items_h3 {
    font-size: 1.2rem !important;
  }
}
.contactWrapper .office__items--box p {
  width: 100%;
  margin-top: 1rem;
}
@media all and (min-width: 768px) and (max-width: 992px) {
  .contactWrapper .office__items--box p {
    font-size: 14px !important;
    margin: 0.4rem;
  }
}
@media all and (min-width: 768px) and (max-width: 992px) {
  .contactWrapper .office__items--box {
    padding: 0.5rem;
  }
}
@media all and (max-width: 480px) {
  .contactWrapper .office__items--box {
    padding: 0.5rem;
  }
}
@media all and (max-width: 480px) {
  .contactWrapper .office__items--box {
    width: 100%;
  }
  .contactWrapper .office__items--box:last-child {
    margin-top: 1rem;
  }
}
@media all and (max-width: 360px) {
  .contactWrapper .office__items {
    flex-wrap: wrap;
  }
}
.contactWrapper .Mapsection {
  border: 1px solid rgb(230, 230, 230);
  margin-top: 1rem;
}
.contactWrapper .Mapsection iframe {
  width: 100%;
  height: 500px;
}
@media all and (max-width: 576px) {
  .contactWrapper .Mapsection iframe {
    height: 350px;
  }
}
.contactWrapper .formWrapper {
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
}
@media all and (max-width: 768px) {
  .contactWrapper .formWrapper {
    margin-top: 1rem;
  }
}

.newsWrapper {
  margin-top: 3rem;
}
.newsWrapper .newsBox {
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
  margin-top: 1rem;
}
.newsWrapper .newsBox h3 {
  color: #005AAB;
  margin-top: 1rem;
}/*# sourceMappingURL=App.css.map */