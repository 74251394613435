.galleryWrapper {
  margin-top: 3rem;
  .mediaWrapper {
    margin-top: 1rem;
    iframe {
      height: 17rem;
      width: 100%;
    }
    img {
      height: 17rem;
      width: 100%;
      object-fit: contain;
      background: rgba(236, 236, 236, 0.356);
    }
  }
}
