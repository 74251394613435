.factoryContainer {
  @media all and (min-width: 768px) and (max-width: 992px) {
    max-width: 90% !important;
  }
}
.factoryWrapper {
  margin-top: 4rem;

  .factoryProducts {
    margin-top: 4rem;
    img {
      margin-top: 3rem;
      width: 100%;
      border-radius: $radius;
    }
  }
  .processWrapper {
    margin-top: 4rem;
    img {
      width: 100%;
      @include responsive-for(lg) {
        margin-top: 4rem;
      }
    }
    .process_desc {
      @include responsive-for(lg) {
        width: 100%;
        line-height: 170% !important;
      }
    }
    .factDetails {
      margin-top: 5rem;

      @media all and (min-width: 768px) {
        &:nth-child(even) {
          .row {
            flex-direction: row-reverse;
          }
        }
      }

      &-image {
        img {
          width: 100%;
        }
      }

      &-desc {
        color: color(success, base);
        ul {
          list-style-type: disc;

          li {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
