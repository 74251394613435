section[role="heroComponent"] {
  position: relative;
  background: #000;
  height: 400px;

  @include responsive-for(sm) {
    height: 450px;
  }

  .imageBox {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    width: 45%;
    min-width: 300px;
    padding: 2rem;
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    text-align: center;

    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 12px 0 rgba(111, 160, 33, 0.103);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    p {
      color: white;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
    }
    @media all and (max-width: 768px) {
      padding: 2rem 1rem;
      h1 {
        font-size: 30px !important;
      }
      p {
        font-size: 14px;
      }
    }
    @include responsive-for(xs) {
    }
  }
}

//layout

.layoutWrapper2 {
  margin: 2rem 0;
  padding: 3rem 0;

  @media all and (min-width: 768px) {
    &:nth-child(odd) {
      .row {
        flex-direction: row-reverse;
      }
      .whyContent2 {
        padding-left: 7rem;
      }
    }
  }
  .whyImage2 {
    padding-top: 3rem;
    img {
      width: 100%;
      @include responsive-for(lg) {
        width: 90%;
        object-fit: contain;
      }
    }
    @include responsive-for(lg) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .row {
    width: 100%;
    margin: auto;
    align-items: center;
    @media all and (min-width: 768px) {
      * {
        padding: 0;
      }
    }
  }
  .whyContent2 {
    background: color(secondary, tint);
    padding: 2rem;
    border-radius: $radius;
    ul {
      padding: 0;
      list-style-image: url("../assets/arrow.png");
      margin-left: 2rem;

      li {
        padding-left: 1rem;
        width: 95%;
        line-height: 200%;
        color: color(text-light);
        font-weight: 300;
        margin-top: 0.1rem;
      }
    }
    p {
      @include responsive-for(xlg) {
        width: 100%;
        line-height: 180%;
      }
    }
    @include responsive-for(xs) {
      padding: 1rem;
    }
  }
  @include responsive-for(md) {
    margin: 0 0 2rem 0;
    padding: 1rem 0;
  }
}

.chairmanWrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;

  p {
    width: 100%;
  }

  &-image {
    height: 22rem;
    img {
      height: 22rem;
      width: 100%;
      object-fit: cover;
      border-radius: 4px 4px 0 0;
      @media all and (min-width: 992px) and (max-width: 1200px) {
        height: 15rem;
        margin-top: 4rem;
      }
    }
    figcaption {
      border-radius: 0 0 4px 4px;
      text-align: center;
      background: color(secondary, base);
      padding: 1rem;
      color: white;
    }
    @media all and (min-width: 480px) and (max-width: 992px) {
      width: 80%;
      margin: auto;
    }
  }
  &-content {
    @include responsive-for(lg) {
      margin-top: 4rem;
    }
    p {
      @include responsive-for(xlg) {
        line-height: 170%;
      }
    }
  }
}

.boardWrapper {
  padding: 3rem;
  margin-top: 2rem;
  @media all and (min-width: 768px) {
    &:nth-child(even) {
      .row {
        flex-direction: row-reverse;
      }
    }
  }

  &-image {
    background: color(secondary, tint);
    padding: 2rem;
    border-radius: 6px;
    height: 100%;
    margin: 0;
    img {
      height: 20rem;
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
      @media all and (min-width: 576px) and (max-width: 992px) {
        height: 30rem;
      }
    }
    &-post {
      padding-top: 1.5rem;
      text-align: center;
      font-weight: 600;
      font-size: 1.1rem;
      color: color(secondary, base);
    }

    &-name {
      text-align: center;
      padding-top: 0.5rem;
      font-weight: 600;
      font-size: 1.5rem;
      color: color(text-dark);
    }
  }

  &-content {
    height: 100%;
    p {
      width: 100%;
      padding: 1rem;
    }
  }
  @include responsive-for(xs) {
    padding: 0;
  }
}

.bwhite {
  background-color: white !important;
}

//team section

.teamHolder {
  margin-top: 5rem;
  .teambox {
    border: $border;
    margin-top: 2rem;
    img {
      padding: 1rem;
      width: 100%;
      height: 20rem;
      object-fit: cover;
    }
    figcaption {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}
